<template>
  <div class="z-4 h-100 position-relative" :style="slidePosition">
    <div class=" position-absolute line5Wrapper"
      style="height:0.5%;width:100%;left:0%;top:37.5%;transform:translate(0%, -50%);">
      <div class=" bg-warning line5" style="height:100%;width:0%"></div>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
/* import { normalize } from "@/utils/ranges" */
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      let response = slidePositionPlain(this.globalUnits, this.slideIndex)
      response.width = "400%"
      return response
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() { },
  methods: {
    line5Left(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation
        .add({
          targets: ".line5Wrapper",
          left: ["0%", "-5%"],
          duration: 600,
          delay: 300,
          easing: "easeInOutQuad"
        })
        .add({
          targets: ".line5Wrapper",
          left: ["-20%", "60%"],
          duration: 600,
          delay: 300,
          easing: "linear"
        })
      /* let progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress) */

      animation.seek(animation.duration * percentage)
    },
    line5Top(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 1200
      })

      animation.add({
        targets: ".line5Wrapper",
        top: ["80%", "32.5%"],
        duration: 600,
        delay: 300,
        easing: "easeInQuart"
      })
      /* let progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress) */

      animation.seek(animation.duration * percentage)
    },
    line5Width(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation
        .add({
          targets: ".line5",
          width: ["0%", "100%"],
          duration: 800,
          delay: 0,
          easing: "easeInOutQuad"
        })
        .add({
          targets: ".line5",
          width: ["100%", "0%"],
          duration: 600,
          delay: 600,
          easing: "linear"
        })
      /* let progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress) */
      animation.seek(animation.duration * percentage)
    }
  },
  watch: {
    innerPosition() {
      this.line5Left(this.innerPosition)
      //this.line5Top(this.innerPosition)
      this.line5Width(this.innerPosition)
    }
  }
}
</script>
<style></style>
